import './style.scss';

const identify = () => {

  return (
    <section className="identifySection">
        <div className="identifySection__title">Identifiez-vous</div>
        <div className="identifySection__text">Click the button that best suits your needs to help manage your Reactiv SUITE license.</div>
        <div className="identifySection__buttons">
            <div className="identifySection__button">Se connecter</div>
            <div className="identifySection__button">Inscrivez-vous</div>
        </div>
    </section>
  );
};

export default identify;