import { useRef } from "react";
import "./style.scss";
import tablette from "../../../assets/main/home/tablette.png";
import tabBlancFR from "../../../assets/main/home/tab_blanc.png";
import tabBlancEN from "../../../assets/main/home/tab_blanc_en.png";
import trait from "../../../assets/main/home/trait.png";
import gear from "../../../assets/main/home/gear.png";
import laptop from "../../../assets/main/home/laptop.png";
import format from "../../../assets/main/home/format.png";
import brush from "../../../assets/main/home/brush.png";
import laptopScena from "../../../assets/main/home/laptopScena.png";
import logos_app from "../../../assets/main/home/logos_app.png";
import logos_plateform from "../../../assets/main/home/logos_plateform.png";
import scenaFOR from "../../../assets/main/home/scenaFOR.png";
import scenaOPS from "../../../assets/main/home/scenaOPS.png";
import scenaCOM from "../../../assets/main/home/scenaCOM.png";
// import { Carousel } from "../../Main/Home/Carousel";
import { Link } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import scenaVideo from "../../../assets/main/home/Intro_Scena.mp4";
import scenaVideoEN from "../../../assets/main/home/Intro_Scena-ENG.mp4";
import iconPause from "../../../assets/main/home/icon_pause.png";
import iconPlay from "../../../assets/main/home/icon_play.png";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";

const Home = () => {
  const { t } = useTranslation();

  const videoElement = useRef(null);
  const { isPlaying, togglePlay } = VideoPlayer(videoElement);

  const btnVideoPlayer = document.querySelector("#btnVideoPlayer");

  if (isPlaying) {
    btnVideoPlayer?.classList.replace(
      "homeSectionPlayer__button",
      "homeSectionPlayer__button--hidden"
    );
  } else {
    btnVideoPlayer?.classList.replace(
      "homeSectionPlayer__button--hidden",
      "homeSectionPlayer__button"
    );
  }



  return (
    <section className="homeSection">
      <div className="homeSection__tablette">
        <div className="homeSectionTablette__image">
          <img src={tablette} alt="" />
          <div className="homeSectionTabletteImage__wrapper">
            <div className="homeSectionTablette__title">
              <h2>{t("home.title")},</h2>
              <p>{t("home.subtitle")}</p>
            </div>
          </div>
        </div>
        <div className="homeSectionTablette__imageHigh">
          { i18n.language === "fr" ? (
            <img src={tabBlancFR} alt="" />
          ) : (
            <img src={tabBlancEN} alt="" />
          ) }
        </div>
      </div>
      <section className="homeSection__presentation">
        <div className="homeSectionPresentation__content">
          <div className="homeSectionPresentationContent__partLeft">
            <div className="homeSectionPresentationContentPartLeft__title">
              <div className="homeSectionPresentationContentPartLeftTitle__image">
                <h2>
                  <Trans i18nKey="home.presentation.title" components={{ 1: <br /> }} />
                </h2>
                <img src={trait} alt="" />
              </div>
            </div>
            <div className="homeSectionPresentationContentPartLeft__informations">
              <div className="homeSectionPresentationContentPartLeftInformations__column">
                <div className="homeSectionPresentationContentPartLeftInformationsColumn__card">
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__image">
                    <img src={gear} alt="gear" />
                  </div>
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__text">
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__title">
                      <h3>{t("home.presentation.main.title")}</h3>
                    </div>
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__details">
                      <p>
                        {t("home.presentation.main.text1")}
                        <br />
                        {t("home.presentation.main.text2")}
                        <br />
                        {t("home.presentation.main.text3")}
                        <br />
                        {t("home.presentation.main.text4")}
                        <br />
                        {t("home.presentation.main.text5")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="homeSectionPresentationContentPartLeftInformationsColumn__card">
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__image">
                    <img src={format} alt="format" />
                  </div>
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__text">
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__title">
                      <h3>{t("home.presentation.format.title")}</h3>
                    </div>
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__details">
                      <p>
                        {t("home.presentation.format.text1")}
                        <br />
                        {t("home.presentation.format.text2")}
                        <br />
                        {t("home.presentation.format.text3")}
                        <br />
                        {t("home.presentation.format.text4")}
                        <br />
                        {t("home.presentation.format.text5")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="homeSectionPresentationContentPartLeftInformations__column">
                <div className="homeSectionPresentationContentPartLeftInformationsColumn__card">
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__image">
                    <img src={laptop} alt="laptop" />
                  </div>
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__text">
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__title">
                      <h3>{t("home.presentation.app.title")}</h3>
                    </div>
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__details">
                      <p>
                        {t("home.presentation.app.text1")}
                        <br />
                        {t("home.presentation.app.text2")}
                        <br />
                        {t("home.presentation.app.text3")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="homeSectionPresentationContentPartLeftInformationsColumn__card">
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__image">
                    <img src={brush} alt="brush" />
                  </div>
                  <div className="homeSectionPresentationContentPartLeftInformationsColumnCard__text">
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__title">
                      <h3>{t("home.presentation.custom.title")}</h3>
                    </div>
                    <div className="homeSectionPresentationContentPartLeftInformationsColumnCardText__details">
                      <p>
                        {t("home.presentation.custom.text1")}
                        <br />
                        {t("home.presentation.custom.text2")}
                        <br />
                        {t("home.presentation.custom.text3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homeSectionPresentationContent__laptopImg">
            <img src={laptopScena} alt="laptopScena" />
          </div>
        </div>
        <div className="homeSectionPresentation__button">
          { i18n.language === 'fr' ?
              <a href='/scena_brochure_fr.pdf' target="_blank">
                {t("home.button.download")}
              </a>
              :
              <a href='/scena_brochure_en.pdf' target="_blank">
                {t("home.button.download")}
              </a>
          }
        </div>
      </section>
      <div className="homeSection__player" onClick={togglePlay}>
        <video
          className="homeSectionPlayer__video"
          ref={videoElement}
          src={i18n.language === 'fr' ? scenaVideo : scenaVideoEN}
          loop
        />
        <div className="homeSectionPlayer__button" id="btnVideoPlayer">
          {isPlaying ? (
            <img src={iconPause} alt="icon pause" className="buttonPause" />
          ) : (
            <img src={iconPlay} alt="icon play" className="buttonPlay" />
          )}
        </div>
      </div>
      <div className="homeSection__compatibility">
        <div className="homeSectionCompatibility__plateform">
          <div className="homeSectionCompatibilityPlateform__image">
            <img src={logos_plateform} alt="logos plateform" />
          </div>
          <div className="homeSectionCompatibilityPlateform__text">
            <h2>{t("home.compatibility.platform.title")}</h2>
          </div>
        </div>
        <div className="homeSectionCompatibility__application">
          <div className="homeSectionCompatibilityApplication__text">
            <h2>{t("home.compatibility.app.title")}</h2>
          </div>
          <div className="homeSectionCompatibilityApplication__image">
            <img src={logos_app} alt="logos app" />
          </div>
        </div>
      </div>
      <section className="homeSection__solution">
        <div className="homeSectionSolution__text">
          <h2>{t("home.solution.title")}</h2>
          <p>{t("home.solution.subtitle")}</p>
        </div>
        <div className="homeSectionSolution__differentSolution">
          <div className="homeSectionSolutionDifferentSolution__scena">
            <div className="homeSectionSolutionDifferentSolutionScena__logo">
              <img src={scenaFOR} alt="logo_scenaFor" />
            </div>
            <div className="homeSectionSolutionDifferentSolutionScena__text">
              <h3 className="homeSectionSolutionDifferentSolutionScenaText-blue">
                Scena <span>FOR</span>
              </h3>
              <h4>{t("home.solution.for.title")}</h4>
              <p>{t("home.solution.for.text1")}</p>
              <br />
              <p>{t("home.solution.for.text2")}</p>
              <br />
              <p>{t("home.solution.for.text3")}</p>
              <br />
              <p>{t("home.solution.for.text4")}</p>
            </div>
            {/* <div className="homeSectionSolutionDifferentSolutionScena__button">
              <Link
                className="homeSectionSolutionDifferentSolutionScenaButton__link"
                to="/solutions/for"
              >
                Learn more
              </Link>
            </div> */}
          </div>
          <div className="homeSectionSolutionDifferentSolution__scena">
            <div className="homeSectionSolutionDifferentSolutionScena__logo">
              <img src={scenaOPS} alt="logo_scenaOps" />
            </div>
            <div className="homeSectionSolutionDifferentSolutionScena__text">
              <h3 className="homeSectionSolutionDifferentSolutionScenaText-red">
                Scena <span>OPS</span>
              </h3>
              <h4>{t("home.solution.ops.title")}</h4>
              <p>{t("home.solution.ops.text1")}</p>
              <br />
              <p>{t("home.solution.ops.text2")}</p>
              <br />
              <p>{t("home.solution.ops.text3")}</p>
            </div>
            {/* <div className="homeSectionSolutionDifferentSolutionScena__button">
              <Link
                className="homeSectionSolutionDifferentSolutionScenaButton__link"
                to="/solutions/ops"
              >
                Learn more
              </Link>
            </div> */}
          </div>
          <div className="homeSectionSolutionDifferentSolution__scena">
            <div className="homeSectionSolutionDifferentSolutionScena__logo">
              <img src={scenaCOM} alt="logo_scenaCom" />
            </div>
            <div className="homeSectionSolutionDifferentSolutionScena__text">
              <h3 className="homeSectionSolutionDifferentSolutionScenaText-yellow">
                Scena <span>EVENTS</span>
              </h3>
              <h4>{t("home.solution.com.title")}</h4>
              <p>{t("home.solution.com.text1")}</p>
              <br />
              <p>{t("home.solution.com.text2")}</p>
              <br />
              <p>{t("home.solution.com.text3")}</p>
              <br />
              <p>{t("home.solution.com.text4")}</p>
            </div>
            {/* <div className="homeSectionSolutionDifferentSolutionScena__button">
              <Link
                className="homeSectionSolutionDifferentSolutionScenaButton__link"
                to="/solutions/com"
              >
                Learn more
              </Link>
            </div> */}
          </div>
        </div>
        <div className="homeSectionSolution__buttons">
          <div className="homeSection__button">
            <Link className="homeSectionButton__link" to="/contact">
              {t("home.button.try")}
            </Link>
          </div>
        </div>
      </section>
      {/* <div className="homeSection__preview">
        <div className="homeSectionPreview__community">
          <div className="homeSectionPreviewCommunity__text">
            <h2>
              Rejoignez notre <br /> communauté d'utilisateurs
            </h2>
          </div>
          <div className="homeSectionPreviewCommunity__images">
            <div className="homeSectionPreviewCommunityImages__case"></div>
            <div className="homeSectionPreviewCommunityImages__case"></div>
            <div className="homeSectionPreviewCommunityImages__case"></div>
            <div className="homeSectionPreviewCommunityImages__case"></div>
            <div className="homeSectionPreviewCommunityImages__case"></div>
            <div className="homeSectionPreviewCommunityImages__case"></div>
          </div>
        </div>
      </div> */}
      {/* <section className="homeSection__carousel">
        <div className="homeSectionCarousel__title">
          Témoignages de nos clients
        </div>
        <Carousel />
      </section> */}
    </section>
  );
};

export default Home;
