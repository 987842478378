import "./style.scss";
import scenaFOR from "../../../assets/main/solutions/scenaFOR.png";
import scenaOPS from "../../../assets/main/solutions/scenaOPS.png";
import scenaCOM from "../../../assets/main/solutions/scenaCOM.png";
// import { Link } from "react-router-dom";

export const Solutions = () => {
  return (
    <section className="solutionsSection">
      <div className="solutionsSection__main">
        <div className="solutionsSectionMain__intro">
          <div className="solutionsSectionMainIntro__title">Solutions</div>
          <div className="solutionsSectionMainIntro__paragraph">
            <p>Une solution adaptée aux usages professionnels</p>
            <br />
            <p>Découvrez nos versions</p>
          </div>
        </div>
        <div className="solutionsSectionMain__card">
          <div className="solutionsSectionMainCard__leftpart">
            <div className="solutionsSectionMainCardLeftpart__title">Scena</div>
            <div className="solutionsSectionMainCardLeftpart__undertitle">
              Compatible avec toutes vos applications de travail préférées
            </div>
            <div className="solutionsSectionMainCardLeftpart__paragraph">
              <p>
                Offrez une nouvelle dynamique de présentation pour maintenir
                l'attention des apprenants.
              </p>
              <br />
              <p>
                Expliquez simplement grâce à l'exploitation de plusieursangles
                de vue et aux annotations.
              </p>
              <br />
              <p>
                Réduisez le temps de préparation de vos formatons.
                <br />
                (Compatible SCORM)
              </p>
            </div>
            {/* <Link
              to="/solutions/for"
              className="solutionsSectionMainCardLeftpart__btn learnMoreButton"
            >
              Learn more
            </Link> */}
          </div>
          <div className="solutionsSectionMainCard__rightpart">
            <img
              src={scenaFOR}
              alt=""
              className="solutionsSectionMainCardRightpart__img"
            ></img>
          </div>
        </div>
        <div className="solutionsSectionMain__card2">
          <div className="solutionsSectionMainCard2__leftpart">
            <img
              src={scenaOPS}
              alt=""
              className="solutionsSectionMainCard2Leftpart__img"
            ></img>
          </div>
          <div className="solutionsSectionMainCard2__rightpart">
            <div className="solutionsSectionMainCard2Rightpart__title">
              Scena
            </div>
            <div className="solutionsSectionMainCard2Rightpart__undertitle">
              Compatible avec toutes vos applications de travail préférées
            </div>
            <div className="solutionsSectionMainCard2Rightpart__paragraph">
              <p>
                Evaluez une situation de crise à partir de plans, cartes, flux
                vidéo et applications en temps réel.
              </p>
              <br />
              <p>
                Simulez un scénario d'intervention à partir de stickers
                personnalisés.
              </p>
              <br />
              <p>
                Réalisez des points de situation. Préentez et partagez-les
                instantanément.
              </p>
            </div>
            {/* <Link
              to="/solutions/ops"
              className="solutionsSectionMainCard2Rightpart__btn learnMoreButton"
            >
              Learn more
            </Link> */}
          </div>
        </div>
        <div className="solutionsSectionMain__card">
          <div className="solutionsSectionMainCard__leftpart">
            <div className="solutionsSectionMainCardLeftpart__title2">
              Scena
            </div>
            <div className="solutionsSectionMainCardLeftpart__undertitle">
              Compatible avec toutes vos applications de travail préférées
            </div>
            <div className="solutionsSectionMainCardLeftpart__paragraph">
              <p>
                Dynamisez votre présentation en favorisant l'interaction et
                l'attractivité de vos produits.
              </p>
              <br />
              <p>
                Offrez une présentation personnalisée avec le bon contenu au bon
                moment.
              </p>
              <br />
              <p>
                Partagez directement vos documents avec votre client à l'issue
                de votre présentation.
                <br />
                (Compatible avec les principaux CRM du marché)
              </p>
            </div>
            {/* <Link
              to="/solutions/com"
              className="solutionsSectionMainCardLeftpart__btn2 learnMoreButton"
            >
              Learn more
            </Link> */}
          </div>
          <div className="solutionsSectionMainCard__rightpart">
            <img
              src={scenaCOM}
              alt=""
              className="solutionsSectionMainCardRightpart__img"
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};
