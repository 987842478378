import "./style.scss";
import backgroundImage from "../../../assets/main/about/backgroundImage.png";
// import fleche from "../../../assets/main/about/fleche.svg";
// import { Link } from "react-router-dom";

export const About = () => {
  return (
    <>
      <section className="aboutSection">
        <img src={backgroundImage} alt="" className="aboutSection__image" />
        <div className="aboutSection__wrapper">
          <div className="aboutSectionWrapper__title">À propos</div>
          {/* <div className="aboutSectionWrapper__caption">
          </div> */}
        </div>
      </section>
      {/* <section>
        <div className="teamSection">
          <div className="teamSection__elevate">
            <div className="teamSectionElevate__title">
              <h2>Want to Elevate your Presence?</h2>
            </div>
            <div className="teamSectionElevate__subTitle">
              <h3>Get in touch!</h3>
            </div>
            <div className="teamSectionElevate__text">
              <h3>
                Click the link below to get in contact with our team of experts!
                We would love to help you make your remote meetings memorable!
              </h3>
            </div>
            <Link to="/" className="teamSectionElevate__link">
              <div className="teamSectionElevateLink__button">
                Contact Us
              </div>
              <img src={fleche} alt="fleche" />
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
