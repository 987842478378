import "./App.scss";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Main/Home";
import About from "./Main/About";
import { Solutions } from "./Main/Solutions";
import { Ops } from "./Main/Solutions/Ops";
import { For } from "./Main/Solutions/For";
import { Com } from "./Main/Solutions/Com";
import { Resources } from "./Main/Resources";
import Identify from "./Main/Indentify";
import Login from "./Main/Login";
import Register from "./Main/Register";
import { Contact } from "./Main/Contact";
import { Routes, Route } from "react-router-dom";
import { useRef } from "react";

function App() {
  const navBar = useRef<HTMLElement>(null);

  function handleScroll() {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 50) {
      navBar?.current?.classList.add("scrollDown");
      navBar?.current?.classList.remove("scrollUp");
    } else {
      navBar?.current?.classList.add("scrollUp");
      navBar?.current?.classList.remove("scrollDown");
    }
  }

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      <header className="app-header" ref={navBar}>
        <Header />
      </header>
      <div className="app-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/solutions/ops" element={<Ops />} />
          <Route path="/solutions/for" element={<For />} />
          <Route path="/solutions/com" element={<Com />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/identify" element={<Identify />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <div className="app-footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
