import "./style.scss";
import tutorials from "../../../assets/main/resources/tutorials.png";
import iceBreaker from "../../../assets/main/resources/ice-breaker.png";
import mapMapping from "../../../assets/main/resources/map-mapping.png";
import fluide from "../../../assets/main/resources/fluide.png";
import paint from "../../../assets/main/resources/paint.png";
import nature from "../../../assets/main/resources/nature.png";
import manageYourLicense from "../../../assets/main/resources/manage-your-license.png";
import documents from "../../../assets/main/resources/documents.png";

export const Resources = () => {
  return (
    <section className="resourcesSection">
      <div className="resourcesSection__intro">
        <div className="resourcesSectionIntro__title">Ressources</div>
        <div className="resourcesSectionIntro__paragraph">
          Check out our solutions to help you elevate your next remote meeting,
          class
        </div>
      </div>
      <div className="resourcesSection__card">
        <div className="resourcesSectionCard__title">Formation</div>
        <div className="resourcesSectionCard__container">
          <div className="resourcesSectionCardContainer__left card_part">
            <div className="resourcesSectionCardContainer__title">
              Tutorials
            </div>
            <img
              src={tutorials}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Easy to use tutorials with a ton of videos to quickly get you
              started.
            </div>
          </div>
          <div className="resourcesSectionCardContainer__middle card_part">
            <div className="resourcesSectionCardContainer__title">
              Ice breaker
            </div>
            <img
              src={iceBreaker}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Answers to all common tech support issues.
            </div>
          </div>
          <div className="resourcesSectionCardContainer__right card_part">
            <div className="resourcesSectionCardContainer__title">
              Map mapping
            </div>
            <img
              src={mapMapping}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Detailed descriptions for all the various settings and
              configurations.
            </div>
          </div>
        </div>
      </div>
      <div className="resourcesSection__card">
        <div className="resourcesSectionCard__title">Template</div>
        <div className="resourcesSectionCard__container">
          <div className="resourcesSectionCardContainer__left card_part">
            <div className="resourcesSectionCardContainer__title">Fluide</div>
            <img
              src={fluide}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Wide range of articles ranging from ideal setup to the art of the
              remote presentation.
            </div>
          </div>
          <div className="resourcesSectionCardContainer__middle card_part">
            <div className="resourcesSectionCardContainer__title">Paint</div>
            <img
              src={paint}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Collection of great webinars with insightful guests discussing
              everything from the neuroscience of engagement to the art of
              communication.
            </div>
          </div>
          <div className="resourcesSectionCardContainer__right card_part">
            <div className="resourcesSectionCardContainer__title">Nature</div>
            <img
              src={nature}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Detailed descriptions for all the various settings and
              configurations.
            </div>
          </div>
        </div>
      </div>
      <div className="resourcesSection__card gray_card">
        <div className="resourcesSectionCard__title">Other Resources</div>
        <div className="resourcesSectionCard__container">
          <div className="resourcesSectionCardContainer__left card_part">
            <div className="resourcesSectionCardContainer__title">
              Manage Your License
            </div>
            <img
              src={manageYourLicense}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Activate or Deactivate your Reactiv SUITE license right from our
              website.
            </div>
          </div>
          <div className="resourcesSectionCardContainer__middle card_part">
            <div className="resourcesSectionCardContainer__title">
              Documents
            </div>
            <img
              src={documents}
              alt=""
              className="resourcesSectionCardContainer__image"
            ></img>
            <div className="resourcesSectionCardContainer__caption">
              Everything you need to setup Reactiv SUITE on your home computer
              or enterprise level system. We make it easy to get started!
            </div>
          </div>
          <div className="resourcesSectionCardContainer__right card_part">
            <div className="resourcesSectionCardContainer__title">
              Brand Guidelines
            </div>
            <div className="resourcesSectionCardContainer__imageReplace"></div>
            <div className="resourcesSectionCardContainer__caption">
              A detailed list of the tools, images, and palettes to effectively
              represent Vizetto and Reactiv SUITE’s branding on your website!
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
