import "./style.scss";

const login = () => {
  return (
    <section className="loginSection">
      <form action="">
        <div className="loginSection__title">Se connecter</div>
        <div className="loginSection__text">Happy to see you again! </div>
        <div className="loginSection__input">
          <div className="loginSection__inputBoxEmail">
            <label htmlFor="">
              Email <span>*</span>
            </label>
            <input type="email" required />
          </div>
          <div className="loginSection__inputBoxPassword">
            <label htmlFor="">
              Mot de passe <span>*</span>
            </label>
            <input type="password" required />
          </div>
        </div>
        <div className="loginSection__reset">Reset password</div>
      </form>
      <div className="loginSection__button">
        <div className="loginSectionButton__submit">Submit</div>
      </div>
    </section>
  );
};

export default login;
