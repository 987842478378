import "./style.scss";
import scenaFOR from "../../../../assets/main/solutions/FOR/scenaFOR.png";
import demo from "../../../../assets/main/solutions/FOR/demo.png";

export const For = () => {
  return (
    <>
      <section className="scenaForSection">
        <div className="scenaForSection__showcase">
          <div className="scenaForSectionShowcase__background">
            <img src={scenaFOR} alt="" />
            <div className="scenaForSectionShowcaseBackground__wrapper">
              <div className="scenaForSectionShowcaseBackgroundWrapper__title">
                <h2>
                  Scena <span>FOR</span>
                </h2>
                <p>Compatible avec toutes vos</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="presentationSection">
        <div className="presentationSection__text">
          <div className="presentationSectionText__management">
            <h3>
              Compatible avec toutes vos <br /> applications de travail préférées
            </h3>
            <p>
                Offrez une nouvelle dynamique de présentation pour maintenir
                l'attention des apprenants.
              </p>
              <br />
              <p>
                Expliquez simplement grâce à l'exploitation de plusieursangles
                de vue et aux annotations.
              </p>
              <br />
              <p>
                Réduisez le temps de préparation de vos formatons.
                <br />
                (Compatible SCORM)
              </p>
          </div>
          <div className="container">
            <div className="presentationSectionText__solution">
              <h3>Une solution <br /> multi-plateforme</h3>
              <p>
                L'outil Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="solutionSection">
        <div className="solutionSection__text">
          <h3>Solutions to fit any space</h3>
        </div>
        <div className="solutionSection__demo">
          <img src={demo} alt="Demo solutions" />
        </div>
        <div className="solutionSection__buttonFor">
          <button>Explore Hardware Bundles</button>
        </div>
      </section>
    </>
  );
};
