import "./style.scss";
import logoScena from "../../assets/logoScena.png";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t} = useTranslation();
  // const footerItems = [
  //   {
  //     title: "Solutions",
  //     path: "/solutions",
  //     options: [
  //       {
  //         label: "Scena - OPS",
  //         path: "/solutions",
  //       },
  //       {
  //         label: "Scena - Formation",
  //         path: "/solutions",
  //       },
  //       {
  //         label: "Scena - Communication",
  //         path: "/solutions",
  //       },
  //     ],
  //   },
    // {
    //   title: "Ressources",
    //   path: "/resources",
    //   options: [
    //     {
    //       label: "Documentation",
    //       path: "/",
    //     },
    //     {
    //       label: "Gestion des licences",
    //       path: "/",
    //     },
    //     {
    //       label: "Tutoriels",
    //       path: "/",
    //     },
    //     {
    //       label: "Nouveautées",
    //       path: "/",
    //     },
    //   ],
    // },
  //   {
  //     title: "À propos",
  //     path: "/about",
  //     options: [
  //       // {
  //       //   label: "L'équipe",
  //       //   path: "/",
  //       // },
  //       // {
  //       //   label: "Témoignages clients",
  //       //   path: "/",
  //       // },
  //       {
  //         label: "Contactez-nous",
  //         path: "/contact",
  //       },
  //     ],
  //   },
  // ];

  return (
    <section className="footerSection">
      <div className="footerSection__main">
        <div className="footerSectionMain_part1">
          <img
            src={logoScena}
            alt=""
            className="footerSectionMainPart__logo"
          ></img>
          {/* <div className="footerSectionMainPart__text">
            <p>contact@scena-app.fr</p>
          </div> */}
        </div>
        {/* {footerItems.map((item, index) => (
          <div className="footerSectionMain_part" key={index}>
            <div className="footerSectionMainPart__title">
              <Link className="footerSectionMainPartText__link" to={item.path}>
                {item.title}
              </Link>
            </div>
            <div className="footerSectionMainPart__text">
              {item.options.map((option, i) => (
                <p key={i}>
                  <Link
                    className="footerSectionMainPartText__link"
                    to={option.path}
                  >
                    {option.label}
                  </Link>
                </p>
              ))}
            </div>
          </div>
        ))} */}
      </div>
      {/* <div className="footerSectionMain_partInstall">
        <div className="footerSectionMainPart__title">Installer</div>
        <div className="footerSectionMainPart__dowloads">
          <div className="footerSectionMainPart__download"></div>
          <div className="footerSectionMainPart__download"></div>
        </div>
      </div> */}
      <div className="footerSection__bottom">
        <p>&copy; {t("footer.right")}</p>
        <p>{t("footer.legal")}</p>
        {/* <p>
          Données personelles (RGPD) | Gestion des cookies | Mentions légales |
          CGV
        </p> */}
      </div>
    </section>
  );
};

export default Footer;
