import "./style.scss";
import section from "../../../assets/main/contact/section.png";
// import phone from "../../../assets/main/contact/phone.png";
import position from "../../../assets/main/contact/position.png";
import schedule from "../../../assets/main/contact/schedule.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const Contact = () => {
  const { t} = useTranslation();
  const captchaRef = useRef<any>(null);

  return (
    <section className="contactSection">
      <div className="contactSection__header">
        <img src={section} alt="" className="contactSectionHeader__background"/>
        <div className="contactSectionHeader__title">{t("contact.title")}</div>
      </div>
      <div className="contactSection__content">
        <div className="contactSectionContent__information">
          <div className="contactSectionContentInformation__company">
            {/* <div className="contactSectionContentInformationCompany__card">
              <div className="contactSectionContentInformationCompanyCard__image">
                <img src={phone} alt="" />
              </div>
              <div className="contactSectionContentInformationCompanyCard__text">
                <div className="contactSectionContentInformationCompanyCardText__title">
                  <h3>Téléphone</h3>
                </div>
                <div className="contactSectionContentInformationCompanyCardText__subTitle">
                  <p>06 00 00 00 00</p>
                </div>
              </div>
            </div> */}
            <div className="contactSectionContentInformationCompany__card">
              <div className="contactSectionContentInformationCompanyCard__image">
                <img src={position} alt="" />
              </div>
              <div className="contactSectionContentInformationCompanyCard__text">
                <div className="contactSectionContentInformationCompanyCardText__title">
                  <h3>{t("contact.info.address")}</h3>
                </div>
                <div className="contactSectionContentInformationCompanyCardText__subTitle">
                  <p>
                    30 Bd de Bellerive <br />
                    92500, Rueil-Malmaison
                  </p>
                  <br />
                  <p>
                    6 Av. Neil Armstrong, <br />
                    33700 Mérignac
                  </p>
                </div>
              </div>
            </div>
            <div className="contactSectionContentInformationCompany__card">
              <div className="contactSectionContentInformationCompanyCard__image">
                <img src={schedule} alt="" />
              </div>
              <div className="contactSectionContentInformationCompanyCard__text">
                <div className="contactSectionContentInformationCompanyCardText__title">
                  <h3>{t("contact.info.hours.title")}</h3>
                </div>
                <div className="contactSectionContentInformationCompanyCardText__subTitle">
                  <p>
                    {t("contact.info.hours.text1")}<br />
                    {t("contact.info.hours.text2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="https://myscena.fr/contact.php" method="POST">
          <div className="contactSectionContentInformation__input">
            <div className="contactSectionContentInformationInput__BoxLast">
              <label htmlFor="">
                {t("contact.form.lastname")} <span>*</span>
              </label>
              <input type="text" name="lastName" required />
            </div>
            <div className="contactSectionContentInformationInput__BoxFirst">
              <label htmlFor="">
                {t("contact.form.firstname")} <span>*</span>
              </label>
              <input type="text" name="firstName" required />
            </div>
            <div className="contactSectionContentInformationInput__BoxCompany">
              <label htmlFor="">
                {t("contact.form.company")} <span>*</span>
              </label>
              <input type="text" name="company" required />
            </div>
            <div className="contactSectionContentInformationInput__BoxEmail">
              <label htmlFor="">
                {t("contact.form.email")} <span>*</span>
              </label>
              <input type="email" name="email" required />
            </div>
            {/* <div className="contactSectionContentInformationInput__country">
              <label htmlFor="pays">
                Pays/Région <span>*</span>
              </label>
              <select id="pays" name="pays">
                <option value="">-Aucun-</option>
                <option value="France">France</option>
                <option value="Belgique">Belgique</option>
                <option value="Canada">Canada</option>
                <option value="États-Unis">États-Unis</option>
              </select>
            </div> */}
            <div className="contactSectionContentInformationInput__BoxHelp">
              <label htmlFor="">{t("contact.form.message")}</label>
              <textarea name="message" />
            </div>
          </div>

          <div className="contactSectionContentInformation__valid">
            <ReCAPTCHA
              className="captcha"
              sitekey="6LeLtEklAAAAAAgeHPK4AAqHMVRPnGNVo9qHzDtY"
              ref={captchaRef}
            />
            <div className="contactSectionContentInformation__button">
              <button className="contactSectionContentInformationButton__submit">
                {t("contact.form.send")}
              </button>
              <button type="reset" className="contactSectionContentInformationButton__reset">
                {t("contact.form.delete")}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="contactSection__remoteMeetings">
        <div className="contactSectionRemoteMeetings__image">
          <img src={meeting} alt="" />
          <div className="contactSectionRemoteMeetingsImage__wrapper">
            <div className="contactSectionRemoteMeetingsImageWrapper__title">
              <h3>Remote Meetings Suck!</h3>
            </div>
            <div className="contactSectionRemoteMeetingsImageWrapper__text">
              <p>
                Remote meetings limit our ability to connect and engage with
                each other. They lack visual dynamics and engagement to ensure
                your meetings have an impact.
              </p>
              <div className="contactSectionRemoteMeetingsImageWrapperText__subText">
                <p>
                  <span>Cluttered UI</span> – it makes it hard for your audience
                  to focus
                </p>
                <p>
                  <span>Awkward pauses</span> – hard to hold audiences’
                  attention
                </p>
                <p>
                  <span>Linear slides</span> – difficult to switch between
                  different types of content
                </p>
                <p>
                  <span>Tiny camera thumbnail</span> – disconnects you from your
                  audience and content
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contactSection__reactivSuite">
        <div className="contactSectionReactivSuite__Background">
          <div className="contactSectionReactivSuiteImageWrapper__left">
            <div className="contactSectionReactivSuiteImageWrapper__title">
              <h3>
                When you captivate, you <br /> change minds!
              </h3>
            </div>
            <div className="contactSectionReactivSuiteImageWrapper__text">
              <p>
                ARE YOU READY TO TRANFORM YOUR NEXT <br /> REMOTE MEETING?
              </p>
            </div>{" "}
            <div className="contactSectionReactivSuiteImageWrapper__button">
              FREE DOWNLOAD
            </div>
          </div>
          <div className="contactSectionReactivSuiteImageWrapper__right">
            <div className="contactSectionReactivSuiteImageWrapper__logo">
              <img src={logoScena} alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};
