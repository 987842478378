import "./style.scss";

const register = () => {
  return (
    <section className="registerSection">
      <form action="">
        <div className="registerSection__title">Inscrivez-vous</div>
        <div className="registerSection__text">Register! </div>
        <div className="registerSection__input">
          <div className="registerSection__inputBoxFirst">
            <label htmlFor="">
              Prénom <span>*</span>
            </label>
            <input type="email" required />
          </div>
          <div className="registerSection__inputBoxLast">
            <label htmlFor="">
              Nom <span>*</span>
            </label>
            <input type="password" required />
          </div>
          <div className="registerSection__inputBoxCompany">
            <label htmlFor="">
              Entreprise <span>*</span>
            </label>
            <input type="text" required />
          </div>
          <div className="registerSection__inputBoxEmail">
            <label htmlFor="">
              Email <span>*</span>
            </label>
            <input type="email" required />
          </div>
          <div className="registerSection__inputBoxPassword">
            <label htmlFor="">
              Mot de passe <span>*</span>
            </label>
            <input type="password" required />
          </div>
          <div className="registerSelection__country">
            <label htmlFor="pays">
              Country/Région <span>*</span>
            </label>
            <select id="pays" name="pays">
              <option value="">Sélectionnez un pays</option>
              <option value="France">France</option>
              <option value="Belgique">Belgique</option>
              <option value="Canada">Canada</option>
              <option value="États-Unis">États-Unis</option>
            </select>
          </div>
        </div>
      </form>
      <div className="registerSection__button">
        <div className="registerSectionButton__submit">Submit</div>
        <div className="registerSectionButton__reset">Reset</div>
      </div>
    </section>
  );
};

export default register;
