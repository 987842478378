import { useEffect, useState } from 'react';

const VideoPlayer = (videoElement: any) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        isPlaying 
            ? videoElement.current.play()
            : videoElement.current.pause();
    }, [isPlaying, videoElement]);

    return { togglePlay, isPlaying };
};
 
export default VideoPlayer;