import React from "react";
import i18n from "../../../i18n";
import "./style.scss";

class SwitchLanguage extends React.Component {

    componentDidMount() {
        const btnEn = document.querySelector("#EN") as HTMLElement;
        const btnFr = document.querySelector("#FR") as HTMLElement;

        const switchLang = (e: any) => {
            if (e.target.id === 'EN') {
                btnEn?.classList.add('selected');
                btnFr?.classList.remove('selected');
                i18n.changeLanguage('en');
            } else {
                btnFr?.classList.add('selected');
                btnEn?.classList.remove('selected');
                i18n.changeLanguage('fr')
            }
        }

        btnEn?.addEventListener('click', switchLang);
        btnFr?.addEventListener('click', switchLang);
    }

    render() {
        return (
            <div className="headerSectionRight__languages">
                <div className="headerSectionRightLanguages__item selected" id="FR">FR</div>
                <div className="headerSectionRightLanguages__separator">|</div>
                <div className="headerSectionRightLanguages__item" id="EN">EN</div>
            </div>
        )
    }
}
export default SwitchLanguage;