import "./style.scss";
import logoScena from "../../assets/logoScena.png";
import message from "../../assets/header/message.svg";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SwitchLanguage from "./Components/SwitchLanguage";

export const Header = () => {
const {t} = useTranslation();
const navBar = useRef<HTMLElement>(null);

const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 50) {
      navBar?.current?.classList.add("scrollDown");
      navBar?.current?.classList.remove("scrollUp");
      setShowButton(false);
    } else {
      navBar?.current?.classList.add("scrollUp");
      navBar?.current?.classList.remove("scrollDown");
      setShowButton(true);
    }
  }

  //Function To Switch Language
/*  window.addEventListener('load', () => {
    const btnEn = document.querySelector("#EN");
    const btnFr = document.querySelector("#FR");

    const switchLang = (e :any) => {
      if (e.target.id === 'EN') {
        btnEn?.classList.add('selected');
        btnFr?.classList.remove('selected');
        i18n.changeLanguage('en');
      } else {
        btnFr?.classList.add('selected');
        btnEn?.classList.remove('selected');
        i18n.changeLanguage('fr')
      }
    }

    btnEn?.addEventListener('click', switchLang);
    btnFr?.addEventListener('click', switchLang);
  });*/


  // const menuItems = [
  //   {
  //     label: "Solutions",
  //     path: "/solutions",
  //     // options: [
  //     //   {
  //     //     label: "Scena - OPS",
  //     //     path: "/solutions/ops",
  //     //   },
  //     //   {
  //     //     label: "Scena - Formation",
  //     //     path: "/solutions/for",
  //     //   },
  //     //   {
  //     //     label: "Scena - Communication",
  //     //     path: "/solutions/com",
  //     //   },
  //     // ],
  //   },
  //   // {
  //   //   label: "Ressources",
  //   //   path: "/resources",
  //   //   options: [
  //   //     {
  //   //       label: "Documentation",
  //   //       path: "/",
  //   //     },
  //   //     {
  //   //       label: "Gestion des licences",
  //   //       path: "/",
  //   //     },
  //   //     {
  //   //       label: "Tutoriels",
  //   //       path: "/",
  //   //     },
  //   //     {
  //   //       label: "Nouveautées",
  //   //       path: "/",
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     label: "À propos",
  //     path: "/about",
  //     // options: [
  //     //   {
  //     //     label: "L'équipe",
  //     //     path: "/",
  //     //   },
  //     //   {
  //     //     label: "Témoignages clients",
  //     //     path: "/",
  //     //   },
  //     //   {
  //     //     label: "Contactez-nous",
  //     //     path: "/contact",
  //     //   },
  //     // ],
  //   },
  // ];

  // const [menuOpen, setMenuOpen] = useState(Array(4).fill(false));

  // const toggleMenu = (index: number) => {
  //   const newMenuOpen = menuOpen.map((menu, i) =>
  //     i === index ? !menu : false
  //   );
  //   setMenuOpen(newMenuOpen);
  // };

  return (
    <>
      <nav className="headerSection" ref={navBar}>
        <div className="headerSection__left">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <img
              src={logoScena}
              alt=""
              className="headerSectionLeft__logo"
            ></img>
          </Link>
          {/* {menuItems.map((item, index) => (
            <div
              key={index}
              className="headerSection__dropdown"
              onMouseEnter={() => toggleMenu(index)}
              onMouseLeave={() => toggleMenu(index)}
            >
              <div className="headerSection__dropdown-label">
                <Link
                  className="footerSectionMainPartText__link"
                  to={item.path}
                >
                  {item.label}
                </Link>
              </div>
              {menuOpen[index] && (
                <ul className="headerSection__dropdown-options">
                  {item.options.map((option, i) => (
                    <li key={i}>
                      {" "}
                      <Link
                        className="footerSectionMainPartText__link"
                        to={option.path}
                      >
                        {option.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))} */}
        </div>
        <div className="headerSection__right">
          <SwitchLanguage />
          {/*
          <div className="headerSectionRight__languages">
            <div className="headerSectionRightLanguages__item selected" id="FR">FR</div>
            <div className="headerSectionRightLanguages__separator">|</div>
            <div className="headerSectionRightLanguages__item" id="EN">EN</div>
          </div>
          */}
          {/* <div key={3} className="headerSection__dropdown" onMouseEnter={() => toggleMenu(3)} onMouseLeave={() => toggleMenu(3)}>
          <div className="headerSection__dropdown-label">S'identifier</div>
          {menuOpen[3] && (
            <ul className="headerSection__dropdown-options">
              <li>Se connecter</li>
              <li>S'inscrire</li>
            </ul>
          )}
        </div> */}
          <Link
            to="/contact"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="headerSectionRight__link"
          >
            {showButton ? (
              <div className="headerSectionRight__button">{t("header.button.contact")}</div>
            ) : (
              <img className="headerSectionRight__icon" src={message} alt="" />
            )}
          </Link>
          {/* <div className="headerSectionRight__button">Version d'essai</div> */}
        </div>
      </nav>
    </>
  );
};

export default Header;
